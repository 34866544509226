#topHeader {
  background-color: white;
  padding: 1.5vh;
  font-size: 1vw;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(180px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  color: #4a4141;
  -webkit-transition: top 0.5s ease-in-out;
  transition: top 0.5s ease-in-out;
}

#topHeader a {
  text-decoration: none;
  color: #4a4141;
}

#topHeader a:hover {
  color: #0779E4;
}

#topHeader i {
  margin-right: 5px;
}

#headerSocial {
  margin-right: 5px;
  cursor: pointer;
}

.navbar {
  background-color: #ffE75E;
  height: 13vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.2rem;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.navbar:hover {
  color: #0779E4;
}

.nav-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
}

.nav-logo {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 5px;
  cursor: pointer;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.nav-logo img {
  height: 12vh;
  width: 12vh;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
}

.nav-logo img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.nav-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  text-align: center;
  margin-right: 2rem;
  font-weight: bold;
}

.nav-menu:hover {
  color: #0779E4;
}

.nav-links {
  color: black;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
}

.nav-links:hover {
  color: #0779E4 !important;
  font-weight: bold;
}

.fa-code {
  margin-left: 1rem;
}

.nav-item {
  line-height: 5vh;
  margin-right: 1rem;
}

.nav-item:hover {
  color: #0779E4;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  -webkit-transition: width 0.7s ease, background-color 0.5s ease;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-icon {
  display: none;
}

.dropdownUl {
  display: none;
  position: absolute;
  background-color: #ffE75E;
}

.dropdownUl:hover {
  color: #0779E4;
  font-weight: bold;
}

.dropdownUl li {
  float: none;
  display: block;
  cursor: pointer;
  line-height: 3;
}

.dropdownUl li a {
  text-decoration: none;
  color: black;
}

.dropdownUl li a:hover {
  color: #0779E4;
  font-weight: bold;
}

.nav-item:hover .dropdownUl {
  display: block;
  float: none;
  padding: 12px 16px;
  text-decoration: none;
  text-align: left;
}

@media screen and (max-width: 798px) {
  .nav-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 12vh;
    left: -110%;
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-size: 2.2vw;
  }
  .nav-menu.active {
    background: #ffE75E;
    left: 0px;
    opacity: 1;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .nav-item .active {
    border: none;
  }
  .nav-links {
    padding: 0 1.5rem;
    width: 100%;
    display: table;
  }
  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: black;
  }
  .nav-item:hover .dropdownUl {
    position: relative;
    text-align: center;
  }
  #topHeader {
    font-size: 1.5vw;
  }
  #topHeader div {
    padding-bottom: 2vh;
  }
  .logo a img {
    margin-left: 5vh;
  }
}

@media screen and (max-width: 486px) {
  .nav-menu {
    font-size: 5vw;
  }
  #topHeader {
    font-size: 2vw;
  }
}

#infantContainer {
  padding-top: 20vh;
}

#infantContainer p {
  margin: auto;
  width: 80%;
  line-height: 5vh;
  font-weight: bold;
  padding: 2vh;
  font-size: larger;
  background-color: white;
}

#infantContainer #programsPagePhoto1 {
  margin: 5vh;
  width: 80%;
  border-radius: 0 20% 0 0;
}

#infantContainer #programsPagePhoto3 {
  margin: 5vh;
  width: 80%;
  border-radius: 0 20% 0 0;
}

#infantContainer #programsPagePhoto2 {
  width: 50%;
  border-radius: 0 20% 0 0;
}

#infantFirstPart {
  background-image: url("../assets/photo-gallery/5.png");
  width: 100%;
  background-repeat: no-repeat;
}

.infantHeaderToddler {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(25%, #ffe75e), color-stop(25%, #e6c4b3), color-stop(50%, #e6c4b3), color-stop(50%, #ffe75e), color-stop(75%, #ffe75e), color-stop(75%, #e6c4b3), to(#e6c4b3));
  background-image: linear-gradient(90deg, #ffe75e 25%, #e6c4b3 25%, #e6c4b3 50%, #ffe75e 50%, #ffe75e 75%, #e6c4b3 75%, #e6c4b3 100%);
}

.infantHeaderSchool {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(25%, #ffe75e), color-stop(25%, #b3c1e6), color-stop(50%, #b3c1e6), color-stop(50%, #ffe75e), color-stop(75%, #ffe75e), color-stop(75%, #b3c1e6), to(#b3c1e6));
  background-image: linear-gradient(90deg, #ffe75e 25%, #b3c1e6 25%, #b3c1e6 50%, #ffe75e 50%, #ffe75e 75%, #b3c1e6 75%, #b3c1e6 100%);
}

.infantHeader {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(29.17%, #ffe75e), color-stop(29.17%, #b3e6cd), color-stop(50%, #b3e6cd), color-stop(50%, #ffe75e), color-stop(79.17%, #ffe75e), color-stop(79.17%, #b3e6cd), to(#b3e6cd));
  background-image: linear-gradient(90deg, #ffe75e 29.17%, #b3e6cd 29.17%, #b3e6cd 50%, #ffe75e 50%, #ffe75e 79.17%, #b3e6cd 79.17%, #b3e6cd 100%);
}

.infantHeaderSchoolAge {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(25%, #ffe75e), color-stop(25%, #b3e6b8), color-stop(50%, #b3e6b8), color-stop(50%, #ffe75e), color-stop(75%, #ffe75e), color-stop(75%, #b3e6b8), to(#b3e6b8));
  background-image: linear-gradient(90deg, #ffe75e 25%, #b3e6b8 25%, #b3e6b8 50%, #ffe75e 50%, #ffe75e 75%, #b3e6b8 75%, #b3e6b8 100%);
}

.infantHeader,
.infantHeaderSchool,
.infantHeaderToddler,
.infantHeaderSchoolAge {
  font-size: 3em !important;
  color: #0779E4;
  padding: 2vh;
  margin: 2vh;
  border: 1px solid #0779E4;
  display: inline-block;
}

#infantContainer2 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  justify-items: center;
  gap: 5vh;
  margin: 10vh 0;
}

#schedule {
  width: 90%;
  text-align: left;
}

#infantContainer2 > div:nth-child(3),
#infantContainer2 > div:nth-child(6) {
  width: 100%;
}

#headSmall {
  font-size: 4vh;
  color: #F12D2D;
}

@media screen and (max-width: 769px) {
  #infantContainer2 {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  p {
    width: auto;
  }
  .infantHeader,
  .infantHeaderSchool,
  .infantHeaderToddler,
  .infantHeaderSchoolAge {
    font-size: 4vh;
  }
  #infantContainer #programsPagePhoto2 {
    width: 50%;
  }
}

@media screen and (max-width: 411px) {
  #infantContainer #infantBaby2,
  #infantContainer #infantBaby3 {
    width: 80%;
  }
  #infantContainer2 span {
    font-size: 4vw;
  }
}

#pageContainer {
  padding-top: 20vh;
  padding-bottom: 10vh;
}

#pageContainer p {
  font-size: 18px;
  margin: auto;
  width: 80%;
  line-height: 5vh;
  font-weight: bold;
  padding: 2vh;
  background-color: white;
}

#pageContainer a {
  text-decoration: none;
  color: #0779E4;
}

#pageContainer a i {
  margin-right: 5px;
}

#pageContainer a:hover {
  color: #F12D2D;
}

#emailAdress {
  margin-left: 5px;
}

#enrollForm {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
}

.sectionHeader3 {
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 3vw;
  font-weight: 900;
  color: #0779E4;
  margin-bottom: 5vh;
}

.card {
  width: 80%;
  text-align: center;
  margin: 5vh 5vh;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
}

.card i {
  margin-top: 2vh;
}

.fa-star,
.fa-clock-o {
  margin-right: 5px;
}

#cardContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

#parHeader {
  font-weight: bold;
  font-size: larger;
  color: #F12D2D;
}

#parHeader2 {
  font-weight: bold;
  color: #F12D2D;
}

#centerClosureList {
  line-height: 3;
  font-size: 20px;
  font-weight: bold;
}

.icon {
  height: 25px;
  width: 25px;
  margin-right: 10px;
}

table {
  margin-top: 2vw;
  margin-left: auto;
  margin-right: auto;
  font-size: larger;
  background-color: white;
}

table,
th,
td {
  border: 2px solid #0779E4;
  border-collapse: collapse;
}

th,
td {
  padding: 15px;
}

td:first-child {
  font-weight: bold;
}

#rateH2 {
  margin-top: 10px;
  color: #F12D2D;
}

#rateExplanation {
  text-align: left;
  line-height: 4;
}

.tableCaption {
  font-weight: bold;
  margin: 10px;
  color: #F12D2D;
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

#pageTable {
  margin-bottom: 2vw;
}

#pageTable th,
#pageTable td {
  background-color: white;
  padding: 10px 5px;
}

#pageTable th th,
#pageTable td th {
  text-align: left;
}

#galleryContainer > div:nth-child(7),
#pageContainer > div {
  margin-top: 5vw;
}

.schoolAgeHeader {
  color: #F12D2D;
  text-transform: uppercase;
  margin-top: 15px;
}

.equal {
  height: 25vh;
  background-color: white;
}

#equalImage {
  width: 150px;
  height: 60px;
}

.tableExp {
  padding: 10px;
}

@media screen and (max-width: 768px) {
  #enrollForm {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
  #cardContainer {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 541px) {
  #cardContainer {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 411px) {
  #enrollForm {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 280px) {
  #galleryContainer h2 {
    font-size: 22px;
  }
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table {
    width: 90%;
  }
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    margin: 0 0 1rem 0;
  }
  tr:nth-child(odd) {
    background: #ccc;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #ccc2c2;
    position: relative;
    padding-left: 50%;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  .tableCaption {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-column-span: all;
            column-span: all;
  }
  #programsPagePhoto1 {
    display: none;
  }
  #pageTable {
    text-align: center;
  }
  #pageTable tr {
    margin: 0;
  }
  #pageTable td {
    padding-left: 0;
    border: none;
  }
  #pageTable th {
    background-color: #ffE75E;
    border: none;
  }
  /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
  td:nth-of-type(1):before {
    content: "";
  }
  td:nth-of-type(2):before {
    content: "1-3 Days";
  }
  table:nth-child(4) > tbody > tr:nth-child(1) > td:nth-child(2):before,
  table:nth-child(4) > tbody > tr:nth-child(2) > td:nth-child(2):before,
  table:nth-child(4) > tbody > tr:nth-child(3) > td:nth-child(2):before {
    content: "1-2 Days";
  }
  table:nth-child(4) > tbody > tr:nth-child(1) > td:nth-child(3):before,
  table:nth-child(4) > tbody > tr:nth-child(2) > td:nth-child(3):before,
  table:nth-child(4) > tbody > tr:nth-child(3) > td:nth-child(3):before {
    content: "3 Days / Part Time";
  }
  table:nth-child(4) > tbody > tr:nth-child(1) > td:nth-child(4):before,
  table:nth-child(4) > tbody > tr:nth-child(2) > td:nth-child(4):before,
  table:nth-child(4) > tbody > tr:nth-child(3) > td:nth-child(4):before {
    content: "4-5 Days";
  }
  td:nth-of-type(3):before {
    content: "4-5 Days";
  }
  td:nth-of-type(4):before {
    content: "Monthly";
  }
  td:nth-of-type(5):before {
    content: "12.5hr a week";
  }
  .schoolAgeCol:nth-of-type(2):before {
    content: "Before & After 4-5 Days";
  }
  .schoolAgeCol:nth-of-type(3):before {
    content: "Before & After 1-3 Days";
  }
  .schoolAgeCol:nth-of-type(4):before {
    content: "Before 1-5 Days";
  }
  .schoolAgeCol:nth-of-type(5):before {
    content: "After 1-5 Days";
  }
}

#galleryTop {
  padding: 10vw;
}

#centerClosureList {
  background-color: white;
}

@media screen and (max-width: 1280px) {
  #galleryTop {
    padding: 14vw;
  }
}

@media screen and (max-width: 768px) {
  #galleryTop {
    padding-top: 25vw;
  }
}

@media screen and (max-width: 411px) {
  #galleryTop {
    padding-top: 32vw;
  }
}

#careerForm {
  border: 6px solid #0779E4;
  margin: 5vh 0;
  border-radius: 5%;
  display: inline-block;
  text-align: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 2;
  padding: 5vh;
  font-weight: bold;
  background-color: white;
}

#careerForm textarea {
  font-family: 'Quicksand', sans-serif;
}

label {
  margin-right: 8px;
}

input {
  width: 100%;
  height: 30px;
}

select {
  height: 30px;
  font-family: 'Quicksand', sans-serif;
  margin-right: 1vh;
}

#formButton {
  font-size: large;
  padding: 5px;
}

#myfile::-webkit-file-upload-button {
  padding: 3px;
}

#formButton,
#myfile::-webkit-file-upload-button {
  background-color: #F12D2D;
  color: white;
  font-family: 'Quicksand', sans-serif;
  width: -webkit-fit-content;
  width: fit-content;
  padding: 0.2em 1em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 2em;
}

#formButton:hover,
#myfile::-webkit-file-upload-button:hover {
  background-color: #0779E4;
}

#myfile {
  width: 70%;
}

#invisibleText {
  display: none;
}

#heart {
  -webkit-animation-name: example;
          animation-name: example;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  color: #F12D2D;
  margin-top: 11vh;
}

@-webkit-keyframes example {
  0% {
    color: #ffE75E;
    left: 0px;
    top: 0px;
  }
  25% {
    color: #0779E4;
    left: 50px;
    top: 0px;
    scale: 1.5;
  }
  50% {
    color: #52de97;
    left: 100px;
    top: 0px;
  }
  75% {
    color: pink;
    left: 150px;
    top: 0px;
  }
  100% {
    color: orange;
    left: 200px;
    top: 0px;
  }
}

@keyframes example {
  0% {
    color: #ffE75E;
    left: 0px;
    top: 0px;
  }
  25% {
    color: #0779E4;
    left: 50px;
    top: 0px;
    scale: 1.5;
  }
  50% {
    color: #52de97;
    left: 100px;
    top: 0px;
  }
  75% {
    color: pink;
    left: 150px;
    top: 0px;
  }
  100% {
    color: orange;
    left: 200px;
    top: 0px;
  }
}

@media screen and (max-width: 415px) {
  #careerForm {
    width: 90%;
    border-radius: 2%;
  }
  textarea {
    width: 95%;
  }
  #formButton,
  #myfile::-webkit-file-upload-button {
    color: black;
  }
}

* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Quicksand", sans-serif;
  background-image: url("../assets/photo-gallery/littlepeoplespalace.png");
  text-align: center;
}

.sectionHeader {
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 5vw;
  font-weight: 900;
  color: #0779E4;
  margin-bottom: 5vh;
  border-bottom: 2px dotted #0779E4;
  padding: 0.2em 1em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 2em;
  display: inline-block;
  background-color: rgba(255, 255, 255, 0.749);
}

.sectionHeaderPrograms {
  font-family: "Ubuntu", sans-serif;
  font-weight: bold;
  font-size: 1.5vw;
  font-weight: 900;
  margin-bottom: 5vh;
}

.sectionHeaderPrograms a {
  color: #0779E4;
  text-decoration: none;
}

.sectionHeaderPrograms a:hover {
  color: #F12D2D;
}

.sectionHeaderPrograms i {
  margin-left: 5px;
}

/*Top Picture Section*/
.topPicture {
  position: relative;
}

.mainphoto {
  width: 100%;
  max-height: 100vh;
}

.perfectphoto {
  width: 35%;
  position: absolute;
  top: 25%;
  left: 30%;
}

/*Buttons*/
.learnMoreButton2,
#SummerFlyer {
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  font-size: 1.6vw;
  padding: 0.6em 1.2em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 2em;
  border: none;
  cursor: pointer;
  text-align: center;
}

.learnMoreButton2 a,
#SummerFlyer a {
  text-decoration: none;
  color: white;
}

.learnMoreButton2:hover,
#SummerFlyer:hover {
  background-color: #0779E4;
  color: white;
}

#SummerFlyer {
  background-color: #F12D2D;
  color: white;
}

.learnMoreButton2 {
  background-color: #ffE75E;
  margin-top: 5vh;
}

.learnMoreButton2 a {
  color: black;
}

.learnMoreButton2 a:hover {
  color: white;
}

/*About Us Section*/
.aboutUs {
  padding-top: 6vh;
}

.aboutLink ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  list-style: none;
  margin: 4vh 0;
  font-size: 1.6vw;
}

.aboutLink ul li {
  margin-right: 2vh;
  padding: 1vh;
  background-color: #ffE75E;
  padding: 0.6em 1.2em;
  margin: 0 0.3em 0.3em 0;
  border-radius: 2em;
  cursor: pointer;
}

.aboutLink ul li:hover {
  background-color: #0779E4;
  color: white;
}

#aboutUsInfoPhotos {
  width: 55%;
  border-radius: 0 20% 0 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#aboutUsInfo,
.ourActivitiesInfo {
  display: -ms-grid;
  display: grid;
  margin: 5vh;
  line-height: 5vh;
  -ms-grid-columns: (minmax(350px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.aboutUsInfoParargaf {
  text-align: center;
  margin: 5vh;
  background-color: white;
}

#headerAboutUs {
  margin-bottom: 8px;
}

/*Our Activities Section*/
.ourActivities {
  background-color: #00ead350;
  padding-top: 6vh;
}

.ourActivitiesInfo {
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  padding: 6vh 0;
}

#ourActivitiesPhoto {
  width: 80%;
  border-radius: 0 20% 0 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: auto;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ourActivitiesLink {
  margin-top: 5vh;
  padding-bottom: 6vh;
}

.ourActivitiesLink img {
  height: 25vh;
  width: 25vh;
}

/*Event Section*/
.eventGalleryCards {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 5vh 0;
}

.eventGallery {
  width: 80%;
  height: 30vh;
  margin: 5vh;
}

.cardEvent {
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 320px;
  margin: auto;
  margin-bottom: 5vh;
  text-align: center;
  background-color: #d6c97c;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  overflow: hidden;
  border-radius: 10%;
  padding: 15px;
  /* Animation */
}

.cardEvent img {
  margin: 0;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border: 2px solid black;
  border-radius: 10%;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.cardEvent img:hover {
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

/*Parent section*/
.parentSection {
  background-color: #fff;
  background-size: 100% 1.2em;
  background-image: linear-gradient(0deg, transparent 79px, #536162 79px, #536162 81px, transparent 81px), linear-gradient(#eee 0.05em, transparent 0.05em);
  margin: 6vh 0;
}

.parentSectionInfo {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(350px, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  justify-items: center;
  text-align: center;
  padding: 6vh 0vh;
}

.parentSectionPhoto {
  margin-bottom: 6vh;
}

.parentSectionPhoto img {
  border-radius: 0 20% 0 0;
  width: 20vw;
  height: 20vw;
  -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.parentSectionText {
  width: 90%;
  line-height: 5vh;
}

/*Contact Section*/
.contactSection {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
      grid-template-columns: 1fr;
  color: white;
}

.contactSection i {
  font-size: 5vw;
}

.contactSection a {
  text-decoration: none;
  color: white;
}

.contactSection a:hover {
  color: #0779E4;
}

.phoneNumberSection,
.adressSection,
.emailSection {
  padding: 5vh;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2fr;
      grid-template-columns: 1fr 2fr;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.phoneNumberSection {
  background-color: #00f2ff;
}

.adressSection {
  background-color: #ffE75E;
}

.emailSection {
  background-color: #F12D2D;
}

.footer,
footer {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(186, 180, 180, 0.476);
}

.footer a,
footer a {
  margin: 0 1vh;
  text-decoration: none;
  color: black;
}

.footer a:hover,
footer a:hover {
  font-weight: bold;
  color: #0779E4;
}

#footerPage {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

#root
> div:nth-child(3)
> div
> div.phoneNumberSection.aos-init.aos-animate
> div.infoPart
> h2:nth-child(2)
> div {
  font-family: "Quicksand", sans-serif;
  color: white;
  font-size: 3vh;
  font-weight: bold;
}

#root
> div:nth-child(3)
> div
> div.phoneNumberSection.aos-init.aos-animate
> div.infoPart
> h2:nth-child(2)
> div:hover {
  color: #0779E4;
}

.construction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: 80vh;
  color: #F12D2D;
  background-image: url("../assets/photo-gallery/littlepeoplespalace.png");
}

/*Mobile view*/
@media screen and (max-width: 1281px) {
  .navbar {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    font-size: 1.3vw;
  }
  .eventGalleryCards {
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
  }
  .parentSectionPhoto img {
    height: 40vh;
  }
  .aboutUsInfoPicture img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

@media screen and (min-width: 769px) {
  .logo {
    margin-top: 0;
  }
  .logo img {
    margin-left: 2vh;
    height: 15vh;
    width: 15vh;
  }
  .logo:hover,
  .nav-links:hover {
    color: white;
  }
  .contactSection {
    -ms-grid-columns: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
  }
  .ourActivitiesInfo {
    -ms-grid-columns: 1fr 2fr;
        grid-template-columns: 1fr 2fr;
  }
  .contactSection i {
    font-size: 3vw;
  }
  .phoneNumberSection,
  .adressSection,
  .emailSection {
    -ms-grid-columns: 1fr 3fr;
        grid-template-columns: 1fr 3fr;
  }
  .sectionHeader {
    font-size: 3vw;
  }
}

@media screen and (max-width: 769px) {
  body {
    background-image: none;
  }
  .topPicture {
    padding-top: 13vh;
  }
  .parentSectionText {
    width: 90%;
  }
  .parentSectionPhoto img {
    width: 80%;
    height: 30vh;
  }
  .sectionHeaderPrograms {
    font-size: 4vw;
  }
  .footer a img {
    width: 10vh;
  }
  #ourActivitiesPhoto {
    width: 80%;
  }
  #aboutUsInfoPhotos {
    width: 80%;
  }
  .eventGalleryCards {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 415px) {
  main {
    padding-top: 50px;
  }
  .learnMoreButton2,
  #SummerFlyer {
    font-size: 4.5vw;
    padding: 1em 1.6em;
  }
  .sectionHeader {
    font-size: 4vh;
    font-weight: 200;
    font-weight: bold;
  }
  .sectionHeaderPrograms {
    font-size: 5vw;
  }
  .aboutLink ul li {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    margin-right: 1vh;
    padding: 1vh;
  }
  .topPicture {
    padding-top: 12vh;
  }
  .perfectphoto {
    top: 40%;
    left: 30%;
  }
  .parentSectionPhoto img {
    width: 60vw;
    height: 60vw;
  }
  .contactSection {
    font-size: 3vw;
  }
  .ourActivities {
    width: 100%;
  }
}

@media screen and (max-width: 281px) {
  .aboutLink ul {
    font-size: 3.5vw;
  }
  h2 {
    font-size: 3vw;
  }
  p {
    font-size: 2vw;
  }
  #aboutUsInfo {
    padding: 0px;
    margin: 0px;
    text-align: center;
  }
  #aboutUsInfo h2 {
    font-size: 4vw;
  }
  #aboutUsInfo p {
    font-size: 3vw;
  }
  .aboutUsInfoParargaf {
    margin: 0px auto;
    padding: 10px;
  }
  .learnMoreButton2 {
    font-size: 2vw;
  }
  .cardEvent {
    width: 220px;
  }
  .parentSectionInfo {
    -ms-grid-columns: (minmax(220px, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  }
}
